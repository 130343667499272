import {ISportEventPlayer} from "@webng-types/write-model";
import React from "react";
import {DprApiImage} from "../../ApiImage";

import {useTranslation} from "react-i18next";
import {ExpandableContent} from "./ExpandableContent";
import {PlayerCard} from "./PlayerCard";

interface SportEventContentViewPlayersProps {
  eventPlayers: ISportEventPlayer[],
}

interface SportEventContentViewPlayerProps {
  eventPlayer: ISportEventPlayer,
}

export function SportEventContentViewPlayerItem({label, value}: { label: string, value?: React.ReactNode | string }) {
  if (value) {
    return <>
      <div className="tik4-pld__l">{label}</div>
      <div className="tik4-pld__v">{value}</div>
    </>

  } else {
    return <></>
  }
}


export function SportEventContentViewPlayers({eventPlayers}: SportEventContentViewPlayersProps) {
  return <>
    {eventPlayers.map((eventPlayer: ISportEventPlayer) => {
      return <SportEventContentViewPlayer eventPlayer={eventPlayer}
                                          key={eventPlayer.player?._id}/>;
    })}
  </>
}

export function SportEventContentViewPlayer({eventPlayer}: SportEventContentViewPlayerProps) {
  // const playerImage = player?.image ? exactSizeImageUrl(player?.image, {width: 64, height: 64}) : undefined
  const {t} = useTranslation('liveblog');

  const player = eventPlayer.player

  if (player?.about || player?.team_member_since || player?.country || player?.birthdate || player?.weight || player?.height || player?.position || player?.number || player?.facebook || player?.instagram || player?.twitter) {
    return <React.Fragment key={eventPlayer.event_player_type}>
      {/* Expandable content */}
      <ExpandableContent
        className={`tik4-pl ${eventPlayer.event_player_type ? `tik4-pl--${eventPlayer.event_player_type}` : ''}`}
        header={<>
          <div className="tik4-plc">
            {player?.image &&
              <div className="tik4-plc__i">
                <DprApiImage className="tik4-plc__i__img" image={player.image} width={56} height={56}
                             fit='contain' alt={t('liveblog.sport.player.img.alt', {name: player?.name})}/>
              </div>
            }
            <div className="tik4-plc__n">
              <div className="tik4-plc__n__ept">{eventPlayer.event_player_title}</div>
              <div className="tik4-plc__n__n">{player?.name}</div>
            </div>
          </div>
        </>}>
        <PlayerCard eventPlayer={eventPlayer}/>
        {/* <div className="tik4-pld tik4-pl__pld">
              <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.number.label')} value={player?.number} />
              <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.country.label')} value={player?.country && new Intl.DisplayNames([i18n.language], { type: "region" }).of(player?.country)} />
              <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.birthdate.label')} value={<NiceDateTime onlyDate={true} skipRelative={true} ts={player?.birthdate} />} />
              <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.weight.label')} value={player?.weight ? `${player?.weight/1000} kg` : undefined} />
              <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.height.label')} value={player?.height ? `${player?.height} cm` : undefined} />
            </div> */}
      </ExpandableContent>
    </React.Fragment>
  } else {
    return <div className="tik4-ca">
      <div className="tik4-plc">
        {player?.image &&
          <div className="tik4-plc__i">
            <DprApiImage className="tik4-plc__i__img" image={player.image} width={56} height={56}
                         fit='contain'/>
          </div>
        }
        <div className="tik4-plc__n">
          <div className="tik4-plc__n__ept">{eventPlayer.event_player_title}</div>
          {player?.name}
        </div>
      </div>
    </div>
  }
}
