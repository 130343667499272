import React, {useCallback, useMemo} from "react";

import {IEvent, IGame, IGameShowResponse} from "@webng-types/write-model";
import {ErrorBoundary, useEnvironment} from "@webng/react-app-common";
import {OnMediaClickHandler} from "./content/MultiMediaContentBlockView";
import {LiveblogMilestoneListItem} from "./LiveblogMilestoneListItem";
import {LiveblogEventRow, LiveblogEventRowExportedProps} from "./LiveblogEventRow";
import {LiveblogMilestoneRow} from "./LiveblogMilestoneRow";
import {LiveblogTagFilter, OnTagClickHandler} from "./LiveblogTagFilter";
import {LiveblogMilestoneList} from "./LiveblogMilestoneList";
import {useTranslation} from "react-i18next";
import TickarooLogo from "./assets/TickarooLogo";
import {CircleLoader} from "@webng/icons-common";
import {ChevronDown, ChevronsUp, ChevronUp, RefreshCw} from "react-feather";
import {MasonryContainer} from "./MasonryContainer";
import {Extension} from "./extensions/Extension";
import Scoreboard from "./scoreboard/Scoreboard";
import {LiveblogGameSummary} from "./LiveblogGameSummary";
import {LiveblogGameSummaryItem} from "./LiveblogGameSummaryItem";
import {LiveblogHighlightSummary} from "./LiveblogHighlightSummary";
import {LiveblogHighlightSummaryItem} from "./LiveblogHighlightSummaryItem";
import {LiveblogHighlightSliderSummary} from "./LiveblogHighlightSliderSummary";
import {LiveblogHighlightSliderSummaryItem} from "./LiveblogHighlightSliderSummaryItem";
import {getHighlightPostText} from "./utils/eventUtils";

export const automaticSummaryTypes = ["chapters", "highlights", "highlights-slider", "game-summary", "none"] as const
export type AutomaticSummaryType = typeof automaticSummaryTypes[number]

export const eventTagNavigationTypes = ["filter-list", "filter-search", "none"] as const
export type EventTagNavigationType = typeof eventTagNavigationTypes[number]

export type OnSummaryItemClickHandler = (e: IEvent) => void

interface LiveblogRowSwitcherProps extends LiveblogEventRowExportedProps {
  game: IGame
  event: IEvent
}

function ErrorComponent() {
  return <React.Fragment/>
}

function LiveblogRowSwitcher({game, event, ...props}: LiveblogRowSwitcherProps) {
  if (event.highlight === "milestone") {
    return <LiveblogMilestoneRow event={event}/>
  } else {
    return <LiveblogEventRow game={game} event={event} {...props} />
  }
}

interface LiveblogProps {
  game: IGame
  rows: IEvent[]
  milestones?: IEvent[]
  goals?: IEvent[]
  highlights?: IEvent[]
  selectedTag?: string
  pendingUpdate?: IGameShowResponse
  onBackToLiveClick?: () => void
  onLoadMoreTopClick?: () => void
  onLoadMoreBottomClick?: () => void
  onApplyPendingUpdates?: () => void
  onMilestoneClick?: OnSummaryItemClickHandler
  onHighlightClick?: OnSummaryItemClickHandler
  onMediaClick?: OnMediaClickHandler
  onTagClick?: OnTagClickHandler
  loadMoreTopLink?: string
  loadMoreBottomLink?: string
  backToLiveLink?: string
  isLoadingBackToLive?: boolean
  isLoadingMoreTop?: boolean
  isLoadingMoreBottom?: boolean
  isLoadingPendingUpdate?: boolean
  automaticSummary: AutomaticSummaryType
  automaticSummaryHighlightsLimit: number
  eventTagNavigation?: EventTagNavigationType
  extension?: Extension
}

function usePreventDefaultCallback(callback: (() => void)|undefined) {
  return useCallback((e: React.UIEvent) => {
    e.preventDefault();
    e.stopPropagation();
    callback && callback()
  }, [callback])
}

function inferLanguage(locale: string){
  if(locale === "de"){
    return "de";
  }
  return "en";
}

function MaybeLiveblogHighlightSliderSummary({automaticSummary, highlights, game, onHighlightClick}: {
  automaticSummary: AutomaticSummaryType,
  highlights: IEvent[] | undefined,
  game: IGame,
  onHighlightClick?: OnSummaryItemClickHandler
}) {

  const displayHighlights = useMemo(() => {
    if(automaticSummary === "highlights-slider" && highlights && highlights.length > 0) {
      return highlights.filter(h => getHighlightPostText(h) !== "")
    } else {
      return []
    }
  }, [automaticSummary, highlights])

  return <>
    {displayHighlights.length > 0 &&
      <LiveblogHighlightSliderSummary highlightCount={displayHighlights.length}>
        {displayHighlights.map(e => <ErrorBoundary key={"highlight-slider" + e.local_id} FallbackComponent={ErrorComponent}>
        <LiveblogHighlightSliderSummaryItem event={e} game={game} onClick={onHighlightClick}/>
      </ErrorBoundary>)}
      </LiveblogHighlightSliderSummary>
    }
  </>;
}

export function Liveblog({
                           game, milestones, goals, highlights, pendingUpdate, rows, selectedTag,
                           onBackToLiveClick, onLoadMoreTopClick, onLoadMoreBottomClick, onApplyPendingUpdates,
                           isLoadingBackToLive, isLoadingMoreTop, isLoadingMoreBottom, isLoadingPendingUpdate,
                           onMilestoneClick, onHighlightClick, onMediaClick, onTagClick,
                           backToLiveLink, loadMoreTopLink, loadMoreBottomLink, automaticSummary, automaticSummaryHighlightsLimit,
                           eventTagNavigation, extension}: LiveblogProps) {
  const {t, i18n} = useTranslation("liveblog");
  const env = useEnvironment();

  const onBackToLiveClickPreventDefault = usePreventDefaultCallback(onBackToLiveClick)
  const onLoadMoreTopClickPreventDefault = usePreventDefaultCallback(onLoadMoreTopClick);
  const onLoadMoreBottomClickPreventDefault = usePreventDefaultCallback(onLoadMoreBottomClick);
  if (game) {
    return <React.Fragment>

      {pendingUpdate &&
        <div className="tik4-pending-update">
          <button className="tik4-pending-update__button tik4-button tik4-button--primary"
                  onClick={onApplyPendingUpdates} type="button">
            <RefreshCw className={`tik4-icon tik4-icon--mr ${isLoadingPendingUpdate ? "tik4-spin" : ''}`}/>
            {t('liveblog.pending_update.button')}
          </button>
        </div>
      }

      <div className="tik4-blog">

        {game?.scoreboard &&
          <Scoreboard scoreboard={game.scoreboard} isMain/>
        }

        {/*currently only games with TeamGameScoreboards are supported*/}
        {automaticSummary === "game-summary" && goals && goals.length > 0 && game.scoreboard?._type === "Tik::Model::Content::Scoreboard::TeamGameScoreboard" &&
          <LiveblogGameSummary>
            {goals.map(goalEvent => <ErrorBoundary key={"goal" + goalEvent.local_id} FallbackComponent={ErrorComponent}>
              <LiveblogGameSummaryItem game={game} event={goalEvent}/>
            </ErrorBoundary>)}
          </LiveblogGameSummary>
        }

        {(eventTagNavigation && (game?.use_event_tags || game?.custom_template) && game?.event_tags && game.event_tags.filter(t => t.show_in_navigation ?? true).length > 0) &&
          <LiveblogTagFilter tags={game.event_tags.filter(t => t.show_in_navigation ?? true)} onClick={onTagClick} selectedTag={selectedTag} eventTagNavigation={eventTagNavigation}/>
        }


        {automaticSummary === "chapters" && milestones && milestones.length > 0 &&
          <LiveblogMilestoneList game={game}>
            {milestones.map(e => <ErrorBoundary key={"milestone" + e.local_id} FallbackComponent={ErrorComponent}>
              <LiveblogMilestoneListItem event={e} onClick={onMilestoneClick}/>
            </ErrorBoundary>)}
          </LiveblogMilestoneList>
        }

        {automaticSummary === "highlights" && highlights && highlights.length > 0 &&
          <LiveblogHighlightSummary game={game}>
            {highlights.filter(e => getHighlightPostText(e) !== "").slice(0, automaticSummaryHighlightsLimit).map(e => <ErrorBoundary key={"highlight" + e.local_id} FallbackComponent={ErrorComponent}>
              <LiveblogHighlightSummaryItem event={e} onClick={onHighlightClick}/>
            </ErrorBoundary>)}
          </LiveblogHighlightSummary>
        }


        <MaybeLiveblogHighlightSliderSummary automaticSummary={automaticSummary}
                                             highlights={highlights?.filter(e => getHighlightPostText(e) !== "").slice(0, automaticSummaryHighlightsLimit)}
                                             onHighlightClick={onHighlightClick}
                                             game={game}
        />


        {(loadMoreTopLink || backToLiveLink) &&
          <div className="tik4-loading-top">
            {backToLiveLink &&
              <a
                className="tik4-loading-top__button tik4-loading-top__button--back-to-live tik4-button tik4-button--primary"
                href={backToLiveLink} onClick={onBackToLiveClickPreventDefault}>
                {isLoadingBackToLive ?
                  <CircleLoader className="tik4-icon tik4-icon--mr tik4-spin"/>
                  :
                  <ChevronsUp className="tik4-icon tik4-icon--mr"/>
                }
                {t('liveblog.back_to_live.button')}
              </a>
            }
            {loadMoreTopLink &&
              <a
                className="tik4-loading-top__button tik4-loading-top__button--load-more tik4-button tik4-button--primary"
                href={loadMoreTopLink} onClick={onLoadMoreTopClickPreventDefault}>
                {isLoadingMoreTop ?
                  <CircleLoader className="tik4-icon tik4-icon--mr tik4-spin"/>
                  :
                  <ChevronUp className="tik4-icon tik4-icon--mr"/>
                }
                {t('liveblog.load_more_top.button')}
              </a>
            }
          </div>
        }


        {rows.length === 0 ?
          <div className="tik4-no-events">
            {game?.event_tags?.length && selectedTag ? t('liveblog.no_events_for_tag', {tag: game.event_tags.filter(tag => tag._id === selectedTag)[0].name}) : t('liveblog.no_events')}
          </div>
          :
          <MasonryContainer className="tik4-event-list">
            {rows.map((row, index) =>
              <ErrorBoundary key={row.local_id} FallbackComponent={ErrorComponent}>
                <LiveblogRowSwitcher game={game} event={row} onMediaClick={onMediaClick} eventExtension={extension}/>
              </ErrorBoundary>)
            }
          </MasonryContainer>
        }

        {loadMoreBottomLink &&
          <div className="tik4-load-more-bottom">
            <a className="tik4-load-more-bottom__button tik4-button tik4-button--primary" href={loadMoreBottomLink}
               onClick={onLoadMoreBottomClickPreventDefault}>
              {isLoadingMoreBottom ?
                <CircleLoader className="tik4-icon tik4-icon--mr tik4-spin"/>
                :
                <ChevronDown className="tik4-icon tik4-icon--mr"/>
              }

              {t('liveblog.load_more_bottom.button')}
            </a>
          </div>
        }

        <div className="tik4-by">
          <a className="tik4-by__link" target="_blank" rel="noreferrer"
             href={`https://www.tickaroo.com/about/${inferLanguage(i18n.language)}/live-blog.html?utm_source=${env.clientId || "editor"}&utm_medium=embedjs-comments&utm_term=liveblog-by-tickaroo&utm_campaign=Product%20Link`}>
            <TickarooLogo className="tik4-by__logo" width={55} height={12}/>
            <span className="tik4-by__tickaroo">Tickaroo</span>
            <span className="tik4-by__powered-by">Live Blog Software</span>
          </a>

        </div>
      </div>
    </React.Fragment>
  } else {
    return <React.Fragment>

    </React.Fragment>
  }
}
