import React, {useEffect, useRef} from "react";
import {EventExtension} from "@webng/liveblog/src";
import classNames from 'classnames';

interface InlineAdViewProps  {
  html: string,
  position: keyof EventExtension
}

export function InlineAdView({html,position}: InlineAdViewProps) {
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!html) return

    try {
      const slotHtml = document.createRange().createContextualFragment(html) // Create a 'tiny' document and parse the html string
      if (divRef.current !== null) {
        divRef.current.innerHTML = '' // Clear the container
        divRef.current.appendChild(slotHtml) // Append the new content
      }
    } catch(e) {
      console.error(e)
    }
  }, [html])

  return <div className={classNames(
    "tik4-extension tik4-extension--inline-ad",
    {
      "tik4-extension--event-top":  position === "EventTop",
      "tik4-extension--above-content tik4-content-block": position === "AboveContent",
      "tik4-extension--below-content tik4-content-block": position === "BelowContent",
      "tik4-extension--below-comments tik4-content-block": position === "BelowComments",
      "tik4-extension--event-bottom": position === "EventBottom"
    })}>
    <div className="tik4-inline-ad">
      <div className="tik4-inline-ad__container" ref={divRef}/>
    </div>
  </div>
}
