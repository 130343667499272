import {DprApiImage} from "../ApiImage";
import React from "react";
import {IEditor} from "@webng-types/write-model/index";

export interface EventAuthorProps {
  className?: string
  editor: IEditor
}

export function EventAuthor({className, editor}: EventAuthorProps) {

  return <div className={className || 'tik4-author'}>
    <div className="tik4-author__wrapper">
      <div className="tik4-author__name" title={editor.name}>
        {editor.name}
      </div>
      { editor.image &&
        <div className="tik4-author__thumb">
          <DprApiImage
              className="tik4-author__thumb__img"
              alt={editor.name}
              image={editor.image}
              width={64}
              height={64}
              fit="cover"
              />
        </div>
      }
    </div>
  </div>;
}

