import {
  AutomaticSummaryType, automaticSummaryTypes,
  ColorScheme,
  colorSchemeValues,
  CommentsMode,
  commentsModeValues,
  EventSharingType,
  eventSharingTypes,
  LayoutAuthorPosition,
  layoutAuthorPositionValues,
  LayoutType,
  layoutValues,
  ProviderConstraints,
  ProviderConstraintValue,
  providerConstraintValues
} from "@webng/liveblog";
import {
  parseBoolean,
  parseJSON, parseLocale,
  parseNumber,
  parseStringEnum,
  parseStringFormat,
  PropsParser,
} from "@webng/validations";
import {IGameShowResponse} from "@webng-types/write-model";
import { EventTagNavigationType, eventTagNavigationTypes } from "@webng/liveblog/src/Liveblog";

// add to backend/backend/app/controllers/api/v5/write/ticker/create.rb too
export const supportedLocales = ['en', 'de', 'en-gb', 'en-ie', 'en-au', 'en-nz', 'zh-tw', 'fr-fr', 'it-it', 'nl-nl'] as const;
export type SupportedLocaleType = typeof supportedLocales[number] | ""

export const refreshValues = ["auto", "notify", "off"] as const
export type RefreshType = typeof refreshValues[number]

export const sortValues = ["asc", "desc"] as const
export type SortOrder = typeof sortValues[number]

export const useCookiesValues = ["none", "functional", "all"] as const
export type UseCookiesType = typeof useCookiesValues[number]

export interface LiveblogThemeConfiguration {
  locale: SupportedLocaleType
  refresh: RefreshType
  sort: SortOrder
  limit: number
  deepLinkLimit: number,
  deepLinkDepth: number,
  webEmbedDefaultConstraint: ProviderConstraintValue
  webEmbedConstraints: ProviderConstraints
  useSlideshow: boolean
  automaticSummary: AutomaticSummaryType
  automaticSummaryHighlightsLimit: number
  eventTagNavigation: EventTagNavigationType
  sharing: EventSharingType
  layout: LayoutType
  layoutAuthorPosition: LayoutAuthorPosition
  colorScheme: ColorScheme
  styleUseCards: boolean
  styleInvertTime: boolean
  styleInvertHighlightEvents: boolean
  styleInvertStickyEvents: boolean
  stylePrimaryColor: string
  stylePrimaryColorDark: string
  styleSecondaryColor: string
  styleSecondaryColorDark: string
  styleTextColor: string
  styleTextColorDark: string
  styleTextMutedColor: string
  styleTextMutedColorDark: string
  styleBgColor: string
  styleBgColorDark: string
  styleEventHighlightBgColor: string
  styleEventHighlightBgColorDark: string
  styleEventStickyBgColor: string
  styleEventStickyBgColorDark: string
  commentsMode: CommentsMode
  useCookies: UseCookiesType
}

export const liveblogThemeConfigurationDefaultValues: LiveblogThemeConfiguration = {
  locale: "",
  refresh: 'notify',
  sort: 'desc',
  sharing: "off",
  limit : 15,
  deepLinkLimit: 50,
  deepLinkDepth: 0,
  webEmbedDefaultConstraint: "consent",
  webEmbedConstraints: {},
  automaticSummary: "chapters",
  automaticSummaryHighlightsLimit: 8,
  eventTagNavigation: "filter-list",
  useSlideshow: true,
  layout: "default",
  layoutAuthorPosition: "top",
  colorScheme: "light",
  styleUseCards: false,
  styleInvertTime: false,
  styleInvertHighlightEvents: false,
  styleInvertStickyEvents: false,
  stylePrimaryColor: "",
  stylePrimaryColorDark: "",
  styleSecondaryColor: "",
  styleSecondaryColorDark: "",
  styleTextColor: "",
  styleTextColorDark: "",
  styleTextMutedColor: "",
  styleTextMutedColorDark: "",
  styleBgColor: "",
  styleBgColorDark: "",
  styleEventHighlightBgColor: "",
  styleEventHighlightBgColorDark: "",
  styleEventStickyBgColor: "",
  styleEventStickyBgColorDark: "",
  commentsMode: "default",
  useCookies: "all"
}

// Liveblog Core Widget

export interface LiveblogCoreWidgetInitialData {
  gameShowResponse?: IGameShowResponse
  uniqueId?: string
  isSSR?: boolean
}

// Liveblog Widget

export interface LiveblogWidgetInitialData extends LiveblogCoreWidgetInitialData {
  configuration?: LiveblogThemeConfiguration
  css?: string
}

// OUTPUT CHANNEL TEMPLATES

export interface Theme {
  _id: string
  name: string
  configuration: LiveblogThemeConfiguration
  customStyleId?: string
  ownerId: string
  isDefault?: boolean
  createdAt?: number
  updatedAt?: number
  updatedBy?: string
  deletedAt?: number
}

export interface ThemeList {
  items: Theme[]
}

export interface ThemeUpdatePayload {
  name?: string
  customStyleId?: string
  configuration: LiveblogThemeConfiguration
}

export interface ThemeCreatePayload {
  name?: string
  customStyleId?: string
  configuration?: LiveblogThemeConfiguration
}

const colorRegex = /^$|^#[0-9A-F]{3}([0-9A-F]{3})?$/

export const liveblogWidgetConfigurationLimitMin = 1;
export const liveblogWidgetConfigurationLimitMax = 1000;

export const liveblogThemeConfigurationPropsParser: PropsParser<LiveblogThemeConfiguration> = {
  locale: i => parseLocale(i, supportedLocales) as SupportedLocaleType || "", //TODO check
  refresh: i => parseStringEnum<RefreshType>("refresh", i, refreshValues, liveblogThemeConfigurationDefaultValues.refresh),
  sort: i => parseStringEnum<SortOrder>("sort", i, sortValues, liveblogThemeConfigurationDefaultValues.sort),
  webEmbedDefaultConstraint: i => parseStringEnum<ProviderConstraintValue>("webEmbedDefaultConstraint", i, providerConstraintValues, liveblogThemeConfigurationDefaultValues.webEmbedDefaultConstraint),
  webEmbedConstraints: i => parseJSON("webEmbedConstraints", i, liveblogThemeConfigurationDefaultValues.webEmbedConstraints),
  automaticSummary: i => parseStringEnum<AutomaticSummaryType>("automaticSummary", i, automaticSummaryTypes, liveblogThemeConfigurationDefaultValues.automaticSummary),
  automaticSummaryHighlightsLimit: i => parseNumber(i, 1, 25, liveblogThemeConfigurationDefaultValues.automaticSummaryHighlightsLimit),
  eventTagNavigation: i => parseStringEnum<EventTagNavigationType>("eventTagNavigation", i, eventTagNavigationTypes, liveblogThemeConfigurationDefaultValues.eventTagNavigation),
  useSlideshow: i => parseBoolean("useSlideshow", i, liveblogThemeConfigurationDefaultValues.useSlideshow),
  sharing: i => parseStringEnum<EventSharingType>("sharing", i, eventSharingTypes, liveblogThemeConfigurationDefaultValues.sharing),
  limit: i => parseNumber(i, liveblogWidgetConfigurationLimitMin, liveblogWidgetConfigurationLimitMax, liveblogThemeConfigurationDefaultValues.limit),
  deepLinkLimit: i => parseNumber(i, 0, liveblogWidgetConfigurationLimitMax, liveblogThemeConfigurationDefaultValues.deepLinkLimit),
  deepLinkDepth: i => parseNumber(i, 0, liveblogWidgetConfigurationLimitMax, 0),
  layout: i => parseStringEnum<LayoutType>("layout", i, layoutValues, liveblogThemeConfigurationDefaultValues.layout),
  layoutAuthorPosition: i => parseStringEnum<LayoutAuthorPosition>("layoutAuthorPosition", i, layoutAuthorPositionValues, liveblogThemeConfigurationDefaultValues.layoutAuthorPosition),
  colorScheme: i => parseStringEnum<ColorScheme>("colorScheme", i, colorSchemeValues, liveblogThemeConfigurationDefaultValues.colorScheme),
  styleUseCards: i => parseBoolean("styleUseCards", i, liveblogThemeConfigurationDefaultValues.styleUseCards),
  styleInvertTime: i => parseBoolean("styleInvertTime", i, liveblogThemeConfigurationDefaultValues.styleInvertTime),
  styleInvertHighlightEvents: i => parseBoolean("styleInvertHighlightEvents", i, liveblogThemeConfigurationDefaultValues.styleInvertHighlightEvents),
  styleInvertStickyEvents: i => parseBoolean("styleInvertStickyEvents", i, liveblogThemeConfigurationDefaultValues.styleInvertStickyEvents),
  stylePrimaryColor: i => parseStringFormat("stylePrimaryColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.stylePrimaryColor),
  stylePrimaryColorDark: i => parseStringFormat("stylePrimaryColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.stylePrimaryColorDark),
  styleSecondaryColor: i => parseStringFormat("styleSecondaryColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleSecondaryColor),
  styleSecondaryColorDark: i => parseStringFormat("styleSecondaryColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleSecondaryColorDark),
  styleTextColor: i => parseStringFormat("styleTextColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleTextColor),
  styleTextColorDark: i => parseStringFormat("styleTextColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleTextColorDark),
  styleTextMutedColor: i => parseStringFormat("styleTextMutedColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleTextMutedColor),
  styleTextMutedColorDark: i => parseStringFormat("styleTextMutedColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleTextMutedColorDark),
  styleBgColor: i => parseStringFormat("styleBgColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleBgColor),
  styleBgColorDark: i => parseStringFormat("styleBgColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleBgColorDark),
  styleEventHighlightBgColor: i => parseStringFormat("styleEventHighlightBgColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleEventHighlightBgColor),
  styleEventHighlightBgColorDark: i => parseStringFormat("styleEventHighlightBgColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleEventHighlightBgColorDark),
  styleEventStickyBgColor: i => parseStringFormat("styleEventStickyBgColor", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleEventStickyBgColor),
  styleEventStickyBgColorDark: i => parseStringFormat("styleEventStickyBgColorDark", i, colorRegex, liveblogThemeConfigurationDefaultValues.styleEventStickyBgColorDark),
  commentsMode: i => parseStringEnum<CommentsMode>("commentsMode", i, commentsModeValues, liveblogThemeConfigurationDefaultValues.commentsMode),
  useCookies: i => parseStringEnum<UseCookiesType>("useCookies", i, useCookiesValues, liveblogThemeConfigurationDefaultValues.useCookies),
}

export interface ChannelWidgetConfiguration {
  channelId: string
  webEmbedDefaultConstraint: ProviderConstraintValue
  webEmbedConstraints: ProviderConstraints
}

export interface CustomStyle {
  _id: string
  ownerId: string
  name: string
  css: string
  createdAt?: number
  updatedAt?: number
  updatedBy?: string
  deletedAt?: number
  deletedBy?: string
}

export interface CustomStyleList {
  items: CustomStyle[]
}

export interface CustomStyleUpdatePayload {
  name: string
  css: string
}

export interface CustomStyleCreatePayload {
  name: string
  css: string
}

export interface IMultiMediaDisplayTypeOption {
  label: string
  value: string
}

export enum MultiMediaDisplayTypeOptionEnums {
  DEFAULT = "default",
  SLIDESHOW = "slideshow",
}
