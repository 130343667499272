
// function findClientStyleTag(name: CssNames) {
//   const tags = document.getElementsByTagName("link");
//   for(let i = 0; i < tags.length; ++i) {
//     const tag = tags[i];
//     if(tag.href) {
//       var match = tag.href.includes(name);
//       if(match) {
//         return tag;
//       }
//     }
//   }
//   return null;
// }
//
// export function createClientStyleTag(name: CssNames) {
//   const style = document.createElement('link');
//   style.href = globalSettings.scriptBaseUrl + '/' + name;
//   style.type = "text/css";
//   style.rel = "stylesheet";
//   return style
// }

import {HTMLAttributeReferrerPolicy} from "react";

function findScriptTag(src: string) {
  const tags = document.getElementsByTagName("script");
  for(let i = 0; i < tags.length; ++i) {
    const tag = tags[i];
    if(tag.src) {
      var match = tag.src === src
      if(match) {
        return tag;
      }
    }
  }
  return null;
}

interface ScriptTagAttributes {
  async?: boolean | undefined;
  crossOrigin?: "anonymous" | "use-credentials" | "" | undefined;
  defer?: boolean | undefined;
  referrerPolicy?: HTMLAttributeReferrerPolicy | undefined;
  src: string;
}

function createScriptTag(attributes: ScriptTagAttributes) {
  const newScript = document.createElement('script');
  // if (onloadCallback) {
  //   newScript.onload = onloadCallback;
  // }

  newScript.src = attributes.src;
  if(attributes.async !== undefined) newScript.async = attributes.async;
  if(attributes.defer !== undefined) newScript.defer = attributes.defer;
  if(attributes.referrerPolicy !== undefined) newScript.referrerPolicy = attributes.referrerPolicy;
  if(attributes.crossOrigin !== undefined) newScript.crossOrigin = attributes.crossOrigin;

  if(document.head) {
    document.head.appendChild(newScript)
  } else if(document.body) {
    document.body.appendChild(newScript)
  } else {
    const firstScript = document.getElementsByTagName('script')[0];
    if (firstScript) {
      firstScript.parentNode!.insertBefore(newScript, firstScript);
    } else {
      console.warn("Could not append script", newScript)
    }
  }

  return newScript;
}

export function ensureScriptTag(attributes: ScriptTagAttributes) {
  if(!findScriptTag(attributes.src)) {
    createScriptTag(attributes);
  }
}
